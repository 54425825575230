import { ComponentStyleConfig } from '@chakra-ui/react';

export const ModalStyles: ComponentStyleConfig = {
  baseStyle: (props) => {
    const { scrollBehavior } = props;
    return {
      dialog: {
        maxH:
          scrollBehavior === 'inside'
            ? 'calc(100% - var(--chakra-sizes-32))'
            : undefined,
        borderRadius: { base: 0, sm: '12px' },
      },
    };
  },
};
